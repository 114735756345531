import Highcharts from 'highcharts';

export function isSeriesHidden(chartTitle: string, seriesName: string) {
    return localStorage.getItem(chartTitle + seriesName) === 'true';
}

function onSeriesClick(series: Highcharts.Series) {
    const someInvisible = series.chart.series.some(s => !s.visible);

    // @ts-ignore
    const chartTitle = series.chart.title.textStr;

    series.chart.series.forEach(s => {
        if (s !== series) {
            if (!someInvisible) {
                s.hide();
                localStorage.setItem(chartTitle + s.name, 'true');
            }
        } else {
            if (s.visible && someInvisible) {
                s.hide();
                localStorage.setItem(chartTitle + s.name, 'true');
            } else {
                s.show();
                localStorage.removeItem(chartTitle + s.name);
            }
        }
    });
}

export const defaultChartOptions: Highcharts.Options = {
    chart: {
        events: {
            render: function () {
                const chart = this;
                // @ts-ignore
                chart.resetButton = chart.renderer.button('Reset Series', 0, 0)
                    .attr({
                        zIndex: 3
                    })
                    .on('click', function () {
                        // @ts-ignore
                        const chartTitle = chart.title.textStr;
                        chart.series.forEach(s => {
                            s.show();
                            localStorage.removeItem(chartTitle + s.name);
                        })
                    })
                    .add();
            }
        }
    },
    plotOptions: {
        series: {
            point: {
                events: {
                    click: function () {
                        onSeriesClick(this.series);
                        return false;
                    }
                }
            },
            events: {
                legendItemClick: function () {
                    onSeriesClick(this);
                    return false;
                }
            }
        }
    }
};

import request from '@/request/request';
import { JournalistStat } from 'mediadb-models';

export const urls = {
    get: '/journalist-stat',
}

export function getJournalistStats() {
    return request.get<JournalistStat[]>(urls.get).then(res => res.data);
}

import React, { useState } from 'react';
import { KeyValue } from 'mediadb-models';
import { useEffectOnce } from 'react-use';
import * as journalistSearchLogService from '@/services/journalist-search-log.service';
import Highcharts from 'highcharts';
import { defaultChartOptions } from '@/components/charts-options';
import HighchartsReact from 'highcharts-react-official';

export default function JournalistSortCount() {
    const [journalistSortCount, setJournalistSortCount] = useState<KeyValue[]>([]);

    useEffectOnce(() => {
        journalistSearchLogService.getSortCount().then(setJournalistSortCount);
    });

    const journalistSortCountOptions: Highcharts.Options = {
        ...defaultChartOptions,
        chart: {
            type: 'column',
        },
        title: {
            text: 'Journalist Sort Count'
        },
        xAxis: {
            categories: journalistSortCount.map(kv => kv.key),
            crosshair: true,
        },
        yAxis: {
            min: 0,
            gridLineDashStyle: 'Dash',
            allowDecimals: false,
            title: {
                text: 'Count'
            }
        },
        series: [
            {
                type: 'column',
                name: 'Sort Count',
                data: journalistSortCount.map(kv => ({
                    y: kv.value
                })),
            }
        ],
    };

    return <HighchartsReact highcharts={Highcharts} options={journalistSortCountOptions}/>;
}

import request from '@/request/request';
import { KeyValue } from 'mediadb-models';

export const urls = {
    get: '/outlet-search-log',
    tookAverage: '/outlet-search-log/took/average',
    queryCount: '/outlet-search-log/query/count',
    paramsCount: '/outlet-search-log/params/count',
    topicsCount: '/outlet-search-log/topics/count',
    sortCount: '/outlet-search-log/sort/count',
    userQueryCount: '/outlet-search-log/user/query/count',
    userOutletCount: '/outlet-search-log/user/outlet/count',
}

export function getTookAverage() {
    return request.get<KeyValue[]>(urls.tookAverage).then(res => res.data);
}

export function getQueryCount() {
    return request.get<KeyValue[]>(urls.queryCount).then(res => res.data);
}

export function getParamsCount() {
    return request.get<KeyValue[]>(urls.paramsCount).then(res => res.data);
}

export function getTopicsCount() {
    return request.get<KeyValue[]>(urls.topicsCount).then(res => res.data);
}

export function getSortCount() {
    return request.get<KeyValue[]>(urls.sortCount).then(res => res.data);
}

export function getUserQueryCount() {
    return request.get<KeyValue[]>(urls.userQueryCount).then(res => res.data);
}

export function getUserOutletCount() {
    return request.get<KeyValue[]>(urls.userOutletCount).then(res => res.data);
}

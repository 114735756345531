import React from 'react';
import Highcharts, { SeriesOptionsType } from 'highcharts';
import { defaultChartOptions, isSeriesHidden } from '@/components/charts-options';
import HighchartsReact from 'highcharts-react-official';
import { JournalistStat } from 'mediadb-models';
// import drilldown from 'highcharts/modules/drilldown';
// import exporting from 'highcharts/modules/exporting';
//
// exporting(Highcharts);

export default function EmailStatus({stats}: {
    stats: JournalistStat[]
}) {
    stats = stats.filter(s => s.emailStatus);

    const {emailStatus} = [...(stats || [])].sort((s1, s2) => s2.createdAt.toString().localeCompare(s1.createdAt.toString()))[0] || {};
    const statuses = emailStatus?.reduce((acc, s) => ({
        ...acc,
        [s.status]: (acc[s.status] || 0) + s.count
    }), {}) || {};

    const chartTitle = 'Email Address Status';

    // const emailStatusOptions: Highcharts.Options = {
    //     ...defaultChartOptions,
    //     chart: {
    //         type: 'column',
    //     },
    //     title: {
    //         text: 'Email Address Status'
    //     },
    //     xAxis: {
    //         type: 'category'
    //     },
    //     yAxis: {
    //         title: {
    //             text: 'Total email address usage'
    //         }
    //     },
    //     legend: {
    //         enabled: false
    //     },
    //     plotOptions: {
    //         series: {
    //             borderWidth: 0,
    //             dataLabels: {
    //                 enabled: true,
    //             }
    //         }
    //     },
    //     tooltip: {
    //         headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
    //         pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b><br/>'
    //     },
    //     series: [
    //         {
    //             type: 'column',
    //             name: 'Usage',
    //             colorByPoint: true,
    //             data: Object.keys(statuses).map(s => ({
    //                 name: s,
    //                 y: statuses[s],
    //                 drilldown: s
    //             }))
    //         }
    //     ],
    //     drilldown: {
    //         breadcrumbs: {
    //             position: {
    //                 align: 'right'
    //             }
    //         },
    //         series: Object.keys(statuses).map(s => ({
    //             type: 'column',
    //             name: s,
    //             id: s,
    //             data: emailStatus.filter(es => es.status === s).map(es => [es.subStatus || 'none', es.count])
    //         }))
    //     }
    // };

    const emailStatusOptions: Highcharts.Options = {
        ...defaultChartOptions,
        chart: {
            type: 'line',
            ...defaultChartOptions.chart,
        },
        title: {
            text: chartTitle
        },
        xAxis: {
            type: 'datetime'
        },
        yAxis: {
            gridLineDashStyle: 'Dash',
            allowDecimals: false,
            title: {
                text: 'Address Counts'
            }
        },
        series: Object.keys(statuses).map(s => ({
            type: 'line',
            name: s,
            visible: !isSeriesHidden(chartTitle, s),
            data: stats.map(stat => ({
                x: new Date(stat.createdAt).getTime(),
                y: stat.emailStatus.find(es => es.status === s)?.count || 0
            }))
        } as SeriesOptionsType))
    };

    return <HighchartsReact highcharts={Highcharts} options={emailStatusOptions}/>;
}
